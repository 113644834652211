import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ProjectApi, useProject, useProjectApi} from "../../State";
import React, {useCallback, useContext, useEffect, useState} from "react";
import frequencyGraph from "./api";
import enhancedGraph from "./enhancedgraph";
import {useNavigate} from "react-router-dom";
import {YMLightButton, YMLoader} from "../../../../ym-components";
import {VerbatimsModal} from "../verbatims/VerbatimModal";
import {ProjectFilterContext} from "../../ProjectFilterContext";

export type GraphUpdateData = {
  computedNbClusters: number;
  lemmas: string[];
  query: UseQueryResult<any, unknown>;
};
export type FrequencyGraphCanvasProps = {
  height: number;
  projectId: string;
  dimensions: string[];
  classes: string[];
  nbCluster: number;
  nbNodes: number;
  excludeOrphans: boolean;
  blacklist: string[];
  subset?: string;
  onGraphUpdate?: (data: GraphUpdateData) => void;
  fontSizeRange?: [number, number];
  exclusionCircleRange?: [number, number];
};

export const FrequencyGraphCanvas = ({
  height,
  projectId,
  dimensions,
  nbCluster,
  nbNodes,
  excludeOrphans,
  blacklist,
  subset,
  onGraphUpdate,
  fontSizeRange,
  exclusionCircleRange,
}: FrequencyGraphCanvasProps) => {
  const { _ProjectAnalysisStats } = useProject();
  const api = useProjectApi() as ProjectApi;
  const navigate = useNavigate();

  const [graphVersion, setGraphVersion] = useState(
    _ProjectAnalysisStats.data.analyseParsingPercentage
  );

  const [graphData, setGraphData] = useState<any>(null);
  const [graphOptions, setGraphOptions] = useState<any>({});

  const [verbatimModalProps, setVerbatimModalProps] = useState({
    show: false,
    graphNodesFilter: "",
    graphDimensionsFilter: "",
  });

  const projectFilter = useContext(ProjectFilterContext);

  const _graph = useQuery({
    queryKey: ["graph", projectId, dimensions, graphVersion, subset, nbNodes, projectFilter.conditions],
    queryFn: async () => {
      const graph = await api.getFrequencyGraph(
        projectId,
        dimensions.join(","),
        subset || "",
        nbNodes,
        projectFilter.conditions
      );
      return {
        graph,
        version: graphVersion,
        empty: graph.nodes.length == 0,
      };
    },
    staleTime: Infinity,
    enabled: !!api,
  });

  const drawGraph = useCallback(
    (data: any) => {
      setGraphOptions({ nbCluster, excludeOrphans, blacklist });
      const { nodes, edges, computedNbClusters } = frequencyGraph(
        data.graph.nodes,
        data.graph.edges,
        nbCluster,
        excludeOrphans,
        blacklist
      );
      enhancedGraph(
        { nodes, edges },
        {
          fontSizeRange: fontSizeRange || [16, 60],
          exclusionCircleRange: exclusionCircleRange || [30, 80],
          debugMode: false,
          height: height,
          width: document.querySelector("#corpusgraph")?.clientWidth || 1150,
          onClick: ({ clusters, node }: any) => {
            setVerbatimModalProps({
              show: true,
              graphDimensionsFilter: dimensions.join(","),
              graphNodesFilter: clusters.join(","),
            });
          },
        }
      );
      setGraphData(data);
      if (onGraphUpdate)
        onGraphUpdate({
          computedNbClusters,
          lemmas: nodes.map((node: any) => node.lemma),
          query: _graph,
        });
    },
    [nbCluster, excludeOrphans, blacklist]
  );

  useEffect(() => {
    if (
      _graph.data != graphData ||
      graphOptions.nbCluster != nbCluster ||
      graphOptions.excludeOrphans != excludeOrphans ||
      graphOptions.blacklist != blacklist
    ) {
      if (_graph.data) drawGraph(_graph.data);
    }
  }, [_graph.data, nbCluster, excludeOrphans, blacklist]);

  return (
    <>
      <VerbatimsModal
        show={verbatimModalProps.show}
        onClose={() =>
          setVerbatimModalProps({
            show: false,
            graphNodesFilter: "",
            graphDimensionsFilter: "",
          })
        }
        graphDimensionsFilter={verbatimModalProps.graphDimensionsFilter}
        graphNodesFilter={verbatimModalProps.graphNodesFilter}
      />
      <div style={{ width: "100%" }}>
        <div>
          <div className={"text-indigo-500 text-sm w-100 text-center"}>
            {_graph.isFetching ? (
              <p>Mise à jour du graphe...</p>
            ) : (
              <p>&nbsp;</p>
            )}
            {graphVersion <
              _ProjectAnalysisStats.data.analyseParsingPercentage && (
              <>
                <p>De nouvelles données sont disponibles</p>
                <div className={"flex flex-col items-center"}>
                  <YMLightButton
                    onClick={() =>
                      setGraphVersion(
                        _ProjectAnalysisStats.data.analyseParsingPercentage
                      )
                    }
                    label={"Recharger le graphe"}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="graph-container -mt-10"
          style={{ height: height, width: "100%" }}
        >
          <YMLoader
            style={{ height: "100%", width: 400, margin: "auto" }}
            message={"Recherche des clusters"}
            loading={_graph.isLoading}
          >
            {_graph?.data?.empty ? (
              <div
                className={
                  "text-indigo-500 text-xl w-100 text-center h-100 pt-24"
                }
              >
                {" "}
                Le graphe ne contient aucune donnée.{" "}
                {graphVersion < 10 &&
                  "Veuillez patienter quelques instants, l'analyse du projet est en cours."}
              </div>
            ) : (
              <svg
                style={{ height: "100%", width: "100%" }}
                id="corpusgraph"
              ></svg>
            )}
          </YMLoader>
        </div>
      </div>
    </>
  );
};
